import "./bootstrap";

import '@vime/core/themes/default.css';


import { BrowserTracing } from "@sentry/tracing";
import { createApp, h } from "vue";
import ClickOutside from "@js/directives/click-outside";
import { scorecardTypes, status, strokesGainedFacets, userTypes, userStatuses } from "./shared/consts";
import { Head, Link, createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import * as Sentry from "@sentry/vue";
import { vfmPlugin } from "vue-final-modal";
import { ZiggyVue } from "ziggy-vue";
import Vue3TouchEvents from "vue3-touch-events";
import VueGtag from 'vue-gtag';

let init = function (vueDir, Layout, domainConstants = null) {
    createInertiaApp({
        resolve: (name) => {
            const page = resolvePageComponent(
                `./components/${vueDir}/${name}.vue`,
                import.meta.glob("./components/**/*.vue")
            );

            // Add default layout file to all pages
            page.then((module) => {
                module.default.layout = module.default.layout || Layout;
            });
            page.layout = page.layout || Layout;

            return page;
        },
        setup({ el, App, props, plugin }) {
            const app = createApp({ render: () => h(App, props) })
                .use(plugin)
                .use(ZiggyVue)
                .use(vfmPlugin)
                .use(Vue3TouchEvents)
                .use(VueGtag, {
                    config: {
                        // id: 'AW-924070550',
                        // id: 'GTM-TFRXX93',
                        id: 'G-VKBEG572EJ', // this tag was used by some CRS items
                        // Disable automatic pageview tracking as we're already doing that elsewhere
                        send_page_view: false,
                    }
                })
                .component("Head", Head)
                .component("InertiaLink", Link)
                .directive("click-outside", ClickOutside);

            // Can be removed with Vue 3.3. Used to auto unwrap refs and avoid using ref.value, allows for providing/injecting reactive data
            // app.config.unwrapInjectedRef = true;

            // Apply constants
            app.config.globalProperties.$scorecardTypes = scorecardTypes;
            app.config.globalProperties.$status = status;
            app.config.globalProperties.$strokesGainedFacets = strokesGainedFacets;
            app.config.globalProperties.$userTypes = userTypes;
            app.config.globalProperties.$userStatuses = userStatuses;
            app.config.globalProperties.$constants = domainConstants;

            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
                environment: import.meta.env.VITE_APP_ENV,
                integrations: [
                    new BrowserTracing({
                        //routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracePropagationTargets: [
                            "localhost",
                            "swingu.com",
                            /^\//,
                        ],
                    }),
                ],
                logErrors: true,
                // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
                tracesSampleRate: 0.1,
                trackComponents: true,
            });

            app.mount(el);
        },
    });

    InertiaProgress.init({
        color: getComputedStyle(document.documentElement).getPropertyValue(
            "--page-status-indicator"
        ),
        showSpinner: false,
    });
};

export { init };
